;(function ($) {
  $(window).on('load', function () {
    initForms()

    // Components loading animations
    $('.view-animation').viewportChecker({
      classToAdd: 'animated',
      offset: 80
    })

    // Phone Concatenation Script For Tracking

    /* 

    Client request

    setTimeout(function () {
      $('em').each(function () {
        var unsliced = $(this).text()
        var sliced = unsliced.slice(0, -2) + '...'
        $(this).text(sliced)
        var linked = 'tel:' + unsliced.replace(/\s/g, '')
        $(this).click(function () {
          if ($(window).width() < 1000) {
            window.location.href = linked
          } else {
            $(this).text(unsliced)
          }
        })
      })
    }, 2000) */
  })
  


  // mobile sliders, like logo rows etc
  // need to loop in order to get the slide count
  var resizeTimer,
    mobileSwiperSlider = [],
    mobileSwiperCount
  $(window).on('resize load', function () {
    clearTimeout(resizeTimer)
    mobileSwiperSlider.forEach(function (slider, index) {
      if (typeof slider !== 'undefined') {
        slider.destroy()
      }
    })

    resizeTimer = setTimeout(function () {
      mobileSwiperCount = 0
      var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')

      mobileSwipers.forEach(function (el, index) {
        var spacing = el.getAttribute('data-spacing')
        var slidesPerView = el.getAttribute('data-spv')
        if (slidesPerView != null) {
          slidesPerView = slidesPerView.split('|')
          slidesPerView[0] = parseInt(slidesPerView[0])
          slidesPerView[1] = parseInt(slidesPerView[1])
          slidesPerView[2] = parseInt(slidesPerView[2])
          if( typeof slidesPerView[4] != 'undefined' ) {
            slidesPerView[4] = parseInt(slidesPerView[4])
          }
        }

        var slideCount = el.querySelectorAll('.swiper-slide').length

        var options = {
          speed: 600,
          slidesPerView: slidesPerView ? slidesPerView[0] : 1,
          watchOverflow: true,
          loop: true,
          simulateTouch: false,
          spaceBetween: spacing ? parseInt(spacing) : 0,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            576: {
              slidesPerView: slidesPerView ? slidesPerView[1] : 3
            },
            768: {
              slidesPerView: slidesPerView ? slidesPerView[2] : 4
            },
            992: {
              slidesPerView: slidesPerView ? slidesPerView[3] : slideCount,
              loop: false
            },
            1536: {
                slidesPerView: slideCount,
                loop: false
            }
          }
        }

        if( slidesPerView && typeof slidesPerView[4] != 'undefined' ) {
          options.breakpoints[1536] = {
            slidesPerView: 4,
            loop: false
          }
        }

        // init slider
        mobileSwiperSlider[mobileSwiperCount] = new Swiper(el, options)
        mobileSwiperCount++
      })
    }, 500)
  })

  // Sticky Header
  $(window).on('scroll load', function () {
    if ($(window).scrollTop() >= 50) {
      $('header').addClass('bg-tertiary scrolled')
      $('header img.animate-on-scroll').addClass('px-4')
    } else {
      $('header').removeClass('bg-tertiary scrolled')
      $('header img.animate-on-scroll').removeClass('px-4')
    }
  })

  // Smooth Scroll To Anchor
  $(document).on('click', 'a[href*="#"]', function (event) {
    event.preventDefault()
    var target = $(this).attr('href')

    if ($(target).length) {
      $('html, body').animate(
        {
          scrollTop: $(target).offset().top - 80
        },
        1500
      )
    }
  })

  // Inline Video Funcionality
  $(document).on('click', '.inline-video-trigger', function () {
    if ($(this).data('video-id')) {
      var iframeClasses = $(this).data('class')

      if ($(this).hasClass('vimeo')) {
        var iframeHTML =
          '<iframe class="' +
          iframeClasses +
          '" src="https://player.vimeo.com/video/' +
          $(this).attr('data-video-id') +
          '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
      } else {
        var iframeHTML =
          '<iframe class="' +
          iframeClasses +
          '" src="https://www.youtube.com/embed/' +
          $(this).attr('data-video-id') +
          '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      }
      $(this)
        .parent('.video-preview-container')
        .addClass('pb-video')
      $(this)
        .parent('.video-preview-container')
        .find('.inline-video-trigger')
        .hide()
      $(this)
        .parent('.video-preview-container')
        .find('.overlay')
        .hide()
      $(this)
        .parent('.video-preview-container')
        .find('iframe')
        .remove()
      $(this)
        .parent('.video-preview-container')
        .append(iframeHTML)
    } else {
      console.error('no video ID provided.')
    }
  })

  function initForms () {
    if ($('[data-zapier]').length) {
      $('[data-zapier]').on('submit', function (e) {
        e.preventDefault()

        var url = $(this).attr('action')
        var data = $(this).serialize()
        var redirect = $(this).attr('data-redirect')
        var form = $(this)[0]

        $(this)
          .find('[type=submit]')
          .html('SENDING...')

        $.ajax({
          url: url + '?' + data,
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          cors: true,
          secure: true,
          success: function (e) {
            console.log(e)
            if( typeof window.dataLayer != 'undefined' ) {
                window.dataLayer.push({
                    event: 'gtm.formSubmissionSitebased',
                    form: form
                  })
            }
           
            window.location.href = redirect
          },
          error: function (e) {
            console.log(e)
          }
        })
      })
    }
  }
})(jQuery)

// Get Current Month Name
function getCurrentMonth () {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  var now = new Date()
  var thisMonth = months[now.getMonth()] // getMonth method returns the month of the date (0-January :: 11-December)
  var output = document.getElementsByClassName('output')

  $('.output').html(thisMonth);
}
getCurrentMonth();
